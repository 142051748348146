.details {
  width: 1680px;
  height: 1080px;
  padding-top: 98px;
  padding-left: 120px; }
  .details h1 {
    margin-top: -20px;
    font-family: "W-Semibold";
    font-size: 136px;
    line-height: 148px;
    color: #fff; }
  .details h2 {
    margin-top: -5px;
    font-family: "W-Medium";
    font-size: 84px;
    line-height: 96px;
    color: #fff;
    opacity: 0.75; }
  .details h2:not(:empty) {
    padding-bottom: 15px; }
  .details h3 {
    margin-top: 10px;
    font-family: "W-Medium";
    font-size: 52px;
    line-height: 60px;
    color: #fff;
    opacity: 0.75; }
  .details h3:not(:empty) {
    padding-bottom: 30px; }
  .details .details-list {
    column-count: 2;
    margin-top: 30px; }
    .details .details-list ul li {
      list-style: none;
      display: inline-block;
      width: 812px;
      padding-bottom: 40px; }
      .details .details-list ul li .list-label {
        font-family: "W-Semibold";
        font-size: 84px;
        line-height: 96px; }
        .details .details-list ul li .list-label span.caption {
          padding-left: 20px;
          padding-right: 20px;
          font-family: "W-Semibold";
          font-size: 52px;
          line-height: 60px; }
        .details .details-list ul li .list-label div.caption {
          margin-top: -10px;
          font-family: "W-Semibold";
          font-size: 52px;
          line-height: 60px;
          padding-bottom: 10px; }
      .details .details-list ul li .list-description {
        font-family: "W-Medium";
        font-size: 52px;
        line-height: 60px;
        margin-top: -10px;
        width: 100%;
        opacity: 0.75; }
      .details .details-list ul li .list-description:not(:empty) {
        padding-bottom: 5px; }
      .details .details-list ul li .list-value {
        font-family: "W-Semibold";
        font-size: 84px;
        line-height: 96px;
        margin-top: -10px;
        width: 100%;
        opacity: 0.75; }
