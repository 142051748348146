.app .show {
  display: block; }

.app .hide {
  display: none; }

.app .container-loader {
  width: 1920px;
  height: 1080px;
  background-color: #000;
  position: absolute;
  overflow: hidden; }

.app .container-logo {
  width: 1920px;
  height: 1080px;
  background-color: #000;
  position: absolute;
  overflow: hidden; }

.app .container-nav {
  width: 1920px;
  height: 1080px;
  overflow: hidden; }

.app .container-view {
  width: 1920px;
  height: 1080px;
  overflow: hidden; }

.app .container-stage {
  width: 1920px;
  height: 1080px;
  overflow: hidden; }

.app .container-frame-preview {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  color: #fff;
  background-color: #000; }
