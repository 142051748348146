.exception {
  width: 1920px;
  height: 1080px;
  padding-top: 98px;
  padding-left: 120px; }
  .exception h2 {
    margin-top: -20px;
    font-family: "W-Semibold";
    font-size: 136px;
    line-height: 148px;
    color: #fff; }
  .exception p.description {
    font-family: "W-Medium";
    font-size: 84px;
    line-height: 96px;
    color: #fff; }
    .exception p.description span.small {
      font-family: "W-Medium";
      font-size: 52px;
      line-height: 60px;
      color: #fff;
      opacity: 0.75;
      display: inline-block; }
    .exception p.description span.large {
      opacity: 0.75; }
  .exception p.description:not(:empty) {
    margin-top: 25px; }
  .exception span.hours {
    font-family: "W-Medium";
    font-size: 136px;
    line-height: 148px;
    color: #fff;
    opacity: 1 !important;
    display: inline-block; }
  .exception span.hours:not(:empty) {
    margin-top: 50px; }
