@font-face {
  font-family: 'W-Medium';
  src: url("../fonts/W-Medium-Pro.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Medium-Italic';
  src: url("../fonts/W-Medium-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Semibold';
  src: url("../fonts/W-Semibold-Pro.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'W-Narrow-Book';
  src: url("../fonts/W-Narr-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

body {
  background-color: #333;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  position: fixed;
  margin: 0;
  padding: 0;
  cursor: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-font-feature-settings: "ss17" 1, "onum" 1, "liga" 1;
  -moz-font-feature-settings: "ss17" 1, "onum" 1, "liga" 1;
  -ms-font-feature-settings: "ss17" 1, "onum" 1, "liga" 1;
  font-feature-settings: "ss17" 1, "onum" 1, "liga" 1;
  font-family: 'W-Medium', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body #root {
    width: 100%;
    height: 100%;
    background-color: #fff; }
  body h1, body h2, body h3, body h4, body p, body ul, body li, body button, body footer, body img, body caption, body span {
    margin: 0;
    padding: 0;
    font-weight: normal; }
  body button, body footer, body img, body caption {
    border: none;
    outline: none; }
  body ul, body li {
    list-style: none;
    display: block; }
  body em {
    font-family: 'W-Medium-Italic', Helvetica, Arial, sans-serif; }
