.nav {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  cursor: pointer;
  background-color: #333; }
  .nav .container-nav-buttons {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 40px;
    display: flex;
    justify-content: center;
    flex-direction: row; }
    .nav .container-nav-buttons .nav-button {
      width: 360px;
      height: 360px;
      padding: 10px;
      font-family: 'W-Semibold';
      font-size: 40px;
      line-height: 40px;
      border: 4px solid #fff;
      margin-bottom: 45px;
      margin-right: 45px;
      background-color: #000;
      color: #fff;
      float: left;
      text-align: center;
      cursor: pointer; }
      .nav .container-nav-buttons .nav-button:active {
        opacity: 0.7; }
      .nav .container-nav-buttons .nav-button.logo {
        background-image: url("../img/cas-logo.svg");
        background-repeat: no-repeat;
        background-position: 0 80px;
        background-size: 100%;
        background-color: #10a74b; }
      .nav .container-nav-buttons .nav-button span {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 360px; }
