.splash {
  width: 1680px;
  height: 1080px;
  padding-top: 98px;
  padding-left: 120px; }
  .splash h1 {
    margin-top: -24px;
    font-family: "W-Semibold";
    font-size: 240px;
    line-height: 240px; }
  .splash .exception {
    margin-top: 0px !important;
    font-size: 136px !important;
    line-height: 148px !important;
    display: inline-block; }
  .splash h2 {
    font-family: "W-Medium";
    font-size: 84px;
    line-height: 96px;
    opacity: 0.75; }
