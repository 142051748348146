.frame {
  position: absolute;
  transition: visibility 0.5s, opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 1920px;
  height: 1080px;
  overflow: hidden; }
  .frame.show-anim {
    visibility: visible;
    opacity: 1; }
  .frame.hide-anim {
    visibility: hidden;
    opacity: 0; }
  .frame span.caps {
    font-variant: all-small-caps; }
