.promos {
  width: 1680px;
  height: 1080px;
  overflow: hidden;
  padding-top: 98px;
  padding-left: 120px; }
  .promos h1 {
    margin-top: -20px;
    font-family: "W-Semibold";
    font-size: 136px;
    line-height: 148px;
    width: 100%; }
  .promos h2 {
    margin-top: -5px;
    font-family: "W-Medium";
    font-size: 84px;
    line-height: 96px;
    color: #fff;
    opacity: 0.75; }
  .promos h2:not(:empty) {
    padding-bottom: 15px; }
  .promos h3 {
    margin-top: 10px;
    font-family: "W-Medium";
    font-size: 52px;
    line-height: 60px;
    color: #fff;
    opacity: 0.75; }
  .promos .promos-list {
    margin-top: 40px; }
    .promos .promos-list ul {
      columns: 2 812px;
      column-gap: 56px;
      height: 810px; }
      .promos .promos-list ul li {
        list-style: none;
        display: inline-block;
        width: 812px;
        height: 810px; }
        .promos .promos-list ul li .promo-image img {
          width: 772px;
          height: auto;
          margin-bottom: 20px;
          border: 15px solid rgba(255, 255, 255, 0.5); }
        .promos .promos-list ul li h1 {
          font-family: "W-Medium";
          font-size: 52px;
          line-height: 60px;
          margin-top: 20px; }
        .promos .promos-list ul li .promo-descritpion {
          font-family: "W-Medium";
          font-size: 52px;
          line-height: 60px;
          opacity: 0.75; }
