#loader #message-container {
  width: 100%;
  height: 100%;
  position: absolute; }
  #loader #message-container p {
    margin: 0;
    padding: 0;
    margin-top: 500px;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    font-family: 'W-Narrow-Book', Helvetica, Arial, sans-serif;
    font-size: 79px;
    line-height: 79px;
    font-weight: normal;
    letter-spacing: 3px; }
